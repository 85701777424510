import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

import {
  ColDef,
  ColGroupDef,
  Column,
  ColumnRowGroupChangedEvent,
  ColumnState,
  ColumnVO,
  FilterModel,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  IServerSideGetRowsParams,
  RowGroupOpenedEvent,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import GeneralUtils from '../../utils/functions/generalUtils';
import { useTranslation } from 'react-i18next';
import {
  getDataModelItemById,
  getDataModelItemsByTenant,
} from '../../service/config-manager.service';
import { IGroup } from '../../utils/entities/dataModel';
import { useParams, useSearchParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ViewTypes } from '../../utils/constants/enums';
import IAgGridTblPayload from '../../utils/entities/ag-grid/IAgGridTblPayload';
import { getWorkItemList } from '../../service/global-query.service';
import IAgGridRowsTaskRes from '../../utils/entities/screen/IAgGridRowsTaskRes';
import { PermissionTypes } from '../../utils/entities/role/IPermission';
import ITask, { ICase } from '../../utils/entities/screen/ITask';
import LocalStorageUtils from '../../utils/functions/localStorageUtils';
import { addAlert } from '../../store/actions/alerts.actions';
import ISlaSettingsEntity from '../../utils/entities/config/ISlaSettingsEntity';
import IDataModelColumn from '../../utils/entities/ag-grid/IDataModelColumn';
import IGroupkeyPagination from '../../utils/entities/ag-grid/pagination/IGroupkeyPagination';
import IGroupKeyPageRequest from '../../utils/entities/ag-grid/pagination/IGroupKeyPageRequest';
import useConfigUpdates from './work-item-utils/useConfigUpdates';
import { CONSTANTS } from '../../utils/constants/constants';
import AgGridTbl from '../../components/generic/AgGrid/table/AgGridTbl';
import IWorkItemRes from '../../utils/entities/screen/tasks/IWorkItemRes';

const CaseList = (props: { slaSettingsEntity: ISlaSettingsEntity | undefined }) => {
  const { t } = useTranslation();
  const viewType = ViewTypes.CASE_LIST;
  const gridRef = useRef<AgGridReact>(null);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const history = createBrowserHistory();

  const tenant: string = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT
  );
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const canRTJRequest = GeneralUtils.checkUserPermissions(PermissionTypes.RTJ_REQUEST);
  const gridOptions: GridOptions = {
    autoGroupColumnDef: {
      headerName: t('AG_GRID.GROUP_HEADER'),
      field: 'group',
      cellRenderer: 'agGroupCellRenderer',
      sortable: true,
      enableRowGroup: false,
      suppressMenu: false,
      suppressMovable: true,
      suppressColumnsToolPanel: true,
    },
    getRowId: (params: GetRowIdParams) => {
      /* All rows will have an unique caseId,
      Excpet the group rows, that rows will have always a single unique element
       in columnConfigurations
      */
      const col = params.data.columnConfigurations?.[0];
      const parentKeys = (params.parentKeys ?? []).join('-');
      return params.data.caseId ?? `${parentKeys}-${col.attributeName}-${col.attributeValue}`;
    },
  };

  const [columns, setColumns] = useState<Array<ColDef | ColGroupDef>>([]);
  const [pageSize, setPageSize] = useState<number>(CONSTANTS.AG_GRID.ROWS_PER_PAGE[1]);
  const [pageNo, setPageNo] = useState<number>(0);
  const [gridApi, setGridApi] = useState<GridReadyEvent | undefined>(undefined);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [selectAllCases, setSelectAllCases] = useState<boolean>(false);
  const [columnsConfigs, setColumnsConfigs] = useState<Array<IDataModelColumn>>([]);
  const [gridSelectedRows, setGridSelectedRows] = useState<Array<ITask> | Array<ICase>>([]);
  const [columnsViews, setColumnsViews] = useState<Array<IDataModelColumn>>([]);
  const [defaultView, setDefaultView] = useState<boolean>(false);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [, setGroupByParam] = useState<Array<ColumnVO>>([]);
  const [groupKeys, setGroupKeys] = useState<Array<string>>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<IGroup | undefined>(undefined);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [payload, setPayload] = useState<IAgGridTblPayload | undefined>(undefined);
  const [caseCreationLoading, setCaseCreationLoading] = useState<boolean>(false);
  const [noRows, setNoRows] = useState<boolean>(false);

  /***************************** Needed for the Clear button *****************************/
  const gridNode = gridRef.current as any;
  const columnState = gridNode?.columnApi?.api?.getColumnState();
  const [filterModel, setFilterModel] = useState(gridApi?.api.getFilterModel());
  const [columnFiltering, setColumnFiltering] = useState<boolean>(true);
  const [columnSorting, setColumnSorting] = useState<boolean>(true);
  const [columnGroup, setColumnGroup] = useState<boolean>(true);
  const [resetColumns, setResetColumns] = useState<boolean>(true);
  useEffect(() => {
    columnState?.forEach((state: ColumnState) => {
      if (state.sort === 'asc' || state.sort === 'desc') {
        setColumnSorting(false);
      }
      if (state.rowGroup) {
        setColumnGroup(false);
      }
    });
    if (filterModel && Object.keys(filterModel).length) {
      setColumnFiltering(false);
    }
  }, [columnState, filterModel]);

  const columnsMoved = () => {
    setResetColumns(false);
  };
  /***************************** End of Needed for the Clear button *****************************/

  useEffect(() => {
    window.addEventListener('beforeunload', function () {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
    });

    setCaseCreationLoading(true);
    getDataModelItemsByTenant(tenant, true, true)
      .then((groups: Array<IGroup>) => {
        setGroups(groups);
      })
      .catch(() => addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') }))
      .finally(() => setCaseCreationLoading(false));

    return () => {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE);
      window.removeEventListener('beforeunload', function () {
        return;
      });
    };
  }, []);

  useEffect(() => {
    openCreateCaseByUrl(searchParams);
  }, [groups]);

  useEffect(() => {
    forceSelectUpdate();
  }, [totalPages, pageNo]);

  useEffect(() => {
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE, pageSize);
    setPageNo(0);
  }, [pageSize]);

  const openCreateCaseByUrl = (searchParams: URLSearchParams) => {
    const itemCode = searchParams?.get(CONSTANTS.URL_PARAMS.CASE_CREATION);
    if (itemCode) {
      getSelectedGroup(itemCode);
    }
  };

  const onFilterChanged = () => {
    setFilterModel(gridApi?.api.getFilterModel());
  };

  const getRowClass = (params: any) => {
    if (params?.data?.status != null && (params?.data?.status as string).toUpperCase() == 'NEW') {
      return 'bold-font';
    }
  };

  const onRowSelected = () => {
    const rows: ICase[] | undefined = gridApi?.api?.getSelectedRows();
    if (rows) {
      setGridSelectedRows(rows.filter((ele) => ele.caseId));
    }
  };

  const onColumnRowGroupChanged = (event: ColumnRowGroupChangedEvent) => {
    setPageNo(0);
    setGroupByParam(
      event?.columns?.map((col: Column) => {
        return {
          id: col.getColId(),
          displayName: col.getColDef().headerName as string,
          field: col.getColDef().field as string,
        };
      }) || []
    );
  };

  const forceSelectUpdate = () => {
    toggleAllCases();
    onRowSelected();
  };

  const onRowGroupOpened = (event: RowGroupOpenedEvent) => {
    const id = event.node.key as string;
    const gr = [...groupKeys];

    const index = gr.indexOf(id);
    index > -1 ? gr.splice(index, 1) : gr.push(id);

    setGroupKeys(gr);
  };

  const toggleAllCases = () => {
    const selectFlag = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
    const gridNode = gridRef.current as any;
    if (gridNode?.api && selectFlag != null) {
      gridNode.api.forEachNode((node: any) => node.setSelected(selectFlag));
    }
  };

  const getCases = async (payload: IAgGridTblPayload) => {
    return await getWorkItemList(payload, CONSTANTS.CASE_LIST).then((response: IWorkItemRes) => {
      if (!response.casePage) return;
      return response.casePage;
    });
  };

  const keepGroupByTotalElements = (groupKeyPagination: IGroupkeyPagination | undefined) => {
    if (!groupKeyPagination) {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS);
      return;
    }

    const savedGroupPagination: IGroupkeyPagination[] | undefined = LocalStorageUtils.getSavedItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS
    );

    if (savedGroupPagination) {
      const indexOf = savedGroupPagination
        .map((gr) => gr.keyName)
        .indexOf(groupKeyPagination.keyName);

      if (indexOf < 0) {
        savedGroupPagination.push(groupKeyPagination);
      } else {
        savedGroupPagination[indexOf] = groupKeyPagination;
      }

      LocalStorageUtils.setSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS,
        savedGroupPagination
      );
    } else {
      LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS, [
        groupKeyPagination,
      ]);
    }
  };

  // Function to fetch data (simulate an API call or data source fetch)
  const fetchData = useCallback(
    async (params: IServerSideGetRowsParams, casePayload: IAgGridTblPayload) => {
      const { request, success, fail } = params;
      const { filterModel, groupKeys } = request;
      const filter = filterModel as FilterModel;
      getCases(casePayload)
        .then((response: IAgGridRowsTaskRes | undefined) => {
          setPayload(casePayload);
          if (response) {
            if (groupKeys.length === 0) {
              setPageNo(response.pageable.pageNumber);
              setTotalPages(response.totalPages - 1);
              setTotalElements(response.totalElements);
            }

            success({
              rowData: prepareTableData(response.content as unknown as ICase[]),
              rowCount: response.content.length,
            });
            toggleAllCases();
            if (!GeneralUtils.isEmpty(filter) && !response.content.length) {
              setPageNo(0);
            }
            if (!pageNo && !response.content.length) {
              gridApi?.api.showNoRowsOverlay();
            } else {
              gridApi?.api.hideOverlay();
            }
            setNoRows(!response.content.length);

            keepGroupByTotalElements(response.groupKeyPagination);
          }
        })
        .catch((error: string) => {
          addAlert({
            type: 'error',
            primaryText: error ?? '',
          });
          fail();
        })
        .finally(() => setDataLoading(false));
    },
    []
  );

  const prepareTableData = (content: Array<ICase>): Array<{ [key: string]: any }> => {
    const data: Array<{ [key: string]: any }> = [...content];
    content.forEach((row) => {
      row.columnConfigurations.forEach((col) => {
        (row as { [key: string]: any })[col.attributeName] = col.attributeValue;
      });
    });
    return data;
  };

  const handleSelectAllCases = () => {
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG, !selectAllCases);
    setSelectAllCases(!selectAllCases);
    toggleAllCases();
  };

  const getContextMenuItems = (params: GetContextMenuItemsParams) => {
    const id: string | undefined = params?.node?.data?.id;
    if (id) {
      return [
        {
          name: 'Open in a new tab',
          action: () => {
            window.open(`${CONSTANTS.PAGES_URL.CASE_DETAILS}/${id}`, '_blank');
          },
        },
        'separator',
        ...CONSTANTS.AG_GRID.CONTEXT_MENU_ITEMS,
      ];
    }
    return CONSTANTS.AG_GRID.CONTEXT_MENU_ITEMS;
  };

  const getSelectedGroup = (selectedItemCode: string) => {
    const id = groups.find((gr) => gr.itemCode === selectedItemCode)?.id;
    if (id) {
      setOpenModal(true);
      getDataModelItemById(id).then((group: IGroup) => {
        setSelectedGroup(group);
      });
    }
  };

  const saveNewPayload = (casePayload: IAgGridTblPayload) => {
    const payload = GeneralUtils.deepCopy(casePayload);

    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.CASE_LIST.PAYLOAD, payload);
  };

  const findNode = (nodes: IRowNode[], nodeId: string): IRowNode | undefined => {
    return nodes.find((node) => node.id === nodeId);
  };

  const getExpandedGroupKeysRecursive = (
    nodes: IRowNode[],
    expandedGroupKeys: string[],
    nodeId: string
  ) => {
    const node = findNode(nodes, nodeId);
    if (node?.key) {
      expandedGroupKeys.push(node.key);
    }

    const parentKey = node?.parent?.id;
    if (parentKey) {
      getExpandedGroupKeysRecursive(nodes, expandedGroupKeys, parentKey);
    } else {
      return expandedGroupKeys;
    }
  };

  const getExpandedGroupKeys = (nodeId: string) => {
    const expandedGroupKeys: string[] = [];

    const nodes = gridApi?.api.getRenderedNodes();
    if (nodes) {
      getExpandedGroupKeysRecursive(nodes, expandedGroupKeys, nodeId);
    }

    return expandedGroupKeys.reverse();
  };

  const increaseGroupKeyPagination = (key: string, childsCount: number): IGroupKeyPageRequest => {
    const pageNumber = Math.round(
      childsCount / LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE)
    );
    return {
      keyName: key,
      loadMoreRequest: pageNumber,
    };
  };

  const handleShowMoreClick = (key: string, childsCount: number, params: ICellRendererParams) => {
    if (params.node.id) {
      const groupKeys: string[] = getExpandedGroupKeys(params.node.id);
      const groupByParams: ColumnVO[] = LocalStorageUtils.getSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS
      );
      const payload: IAgGridTblPayload = LocalStorageUtils.getSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.CASE_LIST.PAYLOAD
      );

      if (payload && key) {
        const newPayload = structuredClone(payload);
        newPayload.groupKeys = groupKeys;
        newPayload.rowGroupCols = groupByParams;
        newPayload.groupKeyPageRequest = increaseGroupKeyPagination(key, childsCount);
        saveNewPayload(newPayload);
        setDataLoading(true);
        getCases(newPayload)
          .then((res) => {
            res &&
              gridApi?.api?.applyServerSideTransaction({
                route: groupKeys,
                add: prepareTableData(res.content as unknown as ICase[]),
              });
          })
          .catch(() => {
            addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') });
          })
          .finally(() => setDataLoading(false));
      }
    }
  };

  /**
   * This is a custom hook which is used to update the columns configuration
   */
  useConfigUpdates(
    {
      columnsConfigs,
      selectAll: selectAllCases,
      handleShowMoreClick,
    },
    (colConfigs: Array<IDataModelColumn>, columns: Array<ColDef | ColGroupDef>) => {
      setColumnsViews(colConfigs);
      setColumns([...columns]);
    }
  );

  return (
    <AgGridTbl
      {...{
        setTotalPages,
        setGroupKeys,
        selectedGroup,
        caseCreationLoading,
        getSelectedGroup,
        groups,
        selectAll: selectAllCases,
        handleSelectAll: handleSelectAllCases,
        payload,
        columnsViews,
        tenant,
        viewType,
        listType: 'CASE_LIST',
        fetchData,
        pageSize,
        pageNo,
        setGroupByParam,
        canRTJRequest,
        gridSelectedRows,
        noRows,
        setDefaultView,
        setColumnSorting,
        setColumnGroup,
        setColumnFiltering,
        setSelectAllChecked,
        setResetColumns,
        defaultView,
        columnFiltering,
        columnSorting,
        columnGroup,
        selectAllChecked,
        resetColumns,
        columns,
        getRowClass,
        onRowSelected,
        onFilterChanged,
        onColumnRowGroupChanged,
        onRowGroupOpened,
        columnsMoved,
        getContextMenuItems,
        setGridSelectedRows,
        columnsConfigs,
        setColumnsConfigs,
        history,
        params,
        gridOptions,
        groupKeys,
        totalPages,
        totalElements,
        setPageSize,
        setPageNo,
        openModal,
        setOpenModal,
        setSelectedGroup,
        gridName: 'CASES',
        selcetAllLabel: t('SELECT_ALL_CASES'),
        setColumnsViews,
        gridRef,
        dataLoading,
        setDataLoading,
        gridApi,
        setGridApi,
        gridNode,
        showCaseCreationModule: true,
        showViewsModule: true,
        showResetModule: true,
      }}
    />
  );
};

export default CaseList;
