import React, { useEffect, useRef, useState } from 'react';
import './DynamicTableQuestion.scss';
import IQuestionnaireTable from '../../../../utils/entities/screen/IQuestionnaireTable';
import { Grid, IconButton, Stack } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ColGroupDef, GridReadyEvent } from 'ag-grid-community';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CONSTANTS } from '../../../../utils/constants/constants';
import dateUtils from '../../../../utils/functions/dateUtils';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import { useTranslation } from 'react-i18next';
import {
  IAttributeObject,
  IQuestionTableAttributes,
} from '../../../../utils/entities/screen/IQuestionTableAttributes';
import { useIScreen } from '../../../../pages/task/Task';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import ErrorMessage from '../../../generic/error-message/ErrorMessage';
import { IAccessToken } from '../../../../utils/entities/authentication';
import IQuestionType from '../../../../utils/entities/questionnaire/IQuestionType';
import SpelUtils from '../../../../utils/functions/spelUtils';
import PlainTextButton from '../../../generic/button/PlainTextButton';
import QuestionTooltip from '../question-tooltip/QuestionTooltip';
import { IPermission, PermissionTypes } from '../../../../utils/entities/role';
import IdAndVStatus from '../IDAndV/IdAndVStatus';
import { ITableHeader } from '../../../../utils/entities/screen/ITableHeader';
import IExpressionContext from 'fso-fincrime-transaction-reviewer-ui/dist/entities/expressions/IExpressionContext';
import { EVENTS_CONSTANTS } from '../../../../utils/constants/event_constants';
import IDataModelColumn from '../../../../utils/entities/ag-grid/IDataModelColumn';
import IBusinessKey from '../../../../utils/entities/businessKey/IBusinessKey';

const DynamicTableQuestion = (props: IQuestionType) => {
  const { question, indexQuestion, requestBody, disabled, locals, contextLocals, entity, error } =
    props;
  const {
    errorObject,
    isCompletedTask,
    hasAssignee,
    assignee,
    loggedUser,
    saveTaskAsDraftClick,
    tableSaved,
    setTableSaved,
    dataModelVersion,
    removeEmptyBKsRows,
  } = useIScreen();
  const gridRefs = useRef([]);
  const gridRef = gridRefs.current as any;
  const { t } = useTranslation();
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const dateFormat: string = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT
  );
  const caseRolePermissions: IPermission[] = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS
  );

  const userCanEdit: boolean = GeneralUtils.checkUserPermissions(
    PermissionTypes.DYNAMIC_QUESTIONNAIRE_EDIT,
    caseRolePermissions
  );
  const userCanDelete: boolean = GeneralUtils.checkUserPermissions(
    PermissionTypes.DYNAMIC_QUESTIONNAIRE_DELETE,
    caseRolePermissions
  );
  const userCanAdd: boolean = GeneralUtils.checkUserPermissions(
    PermissionTypes.DYNAMIC_QUESTIONNAIRE_ADD,
    caseRolePermissions
  );
  const defaultColumnDef = {
    flex: 1,
    sortable: true,
    filter: true,
    resizable: true,
    minWidth: 100,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  const [gridApi, setGridApi] = useState<GridReadyEvent>();
  const [rowData, setRowData] = useState<Array<Record<string, string | boolean | null>>>();
  const [dynamicTable, setDynamicTable] = useState<IQuestionnaireTable | undefined>();
  const [businessKeys, setBusinessKeys] = useState<Array<string>>([]);
  const [businessKeysChanged, setBusinessKeysChanged] = useState<{
    [key: string]: Array<string>;
  }>({});

  useEffect(() => {
    question.wasChanged = { [entity]: false };
  }, []);

  const changeIsNewItem = (table: IQuestionnaireTable | undefined) => {
    if (table) {
      Object.keys(table.tableAttributes).forEach((key: string) => {
        if (table.tableAttributes[key]?.isNewItem) {
          table.tableAttributes[key].isNewItem = false;
        }
        question.dataTable = table;
      });
    }
  };
  const updateTableAttrs = (currentTable: IQuestionnaireTable | undefined) => {
    if (currentTable) {
      const entityIds: string[] = currentTable.entityIds;
      const headers: string[] = currentTable.tableHeaders.map(
        (header: ITableHeader) => header.attributeName
      );
      entityIds.forEach((entityId: string) => {
        if (
          currentTable.tableAttributes &&
          currentTable.tableAttributes[entityId]?.attributes.length != headers.length
        ) {
          const attrs: string[] = currentTable.tableAttributes[entityId]?.attributes.flatMap(
            (attr) => Object.keys(attr.attribute)
          );
          headers.forEach((header: string) => {
            if (!attrs?.includes(header)) {
              currentTable.tableAttributes[entityId]?.attributes.push({
                attribute: {
                  [header]: '',
                },
                isModified: false,
                isBusinessKey: businessKeys.some((key: string) => key === header),
              });
            }
          });
        }
      });
    }
    return currentTable;
  };

  useEffect(() => {
    setDynamicTable(updateTableAttrs(question.dataTable));
    getRowData(question.dataTable);
  }, [requestBody, errorObject]);

  useEffect(() => {
    getRowData(question.dataTable);
  }, [gridApi, errorObject]);

  useEffect(() => {
    if (dataModelVersion) {
      const bks: Array<string> = GeneralUtils.getTableBKS(dataModelVersion, question);
      setBusinessKeys(bks);
    } else {
      setBusinessKeys([]);
    }
  }, [dataModelVersion]);

  useEffect(() => {
    const tableAttributes: Array<IAttributeObject> = entityAtrributes();
    const isBusinessKey = (at: IAttributeObject): boolean => {
      return businessKeys.includes(Object.keys(at.attribute)[0]);
    };
    if (tableAttributes) {
      tableAttributes.forEach((attribute) => {
        attribute['isBusinessKey'] = isBusinessKey(attribute);
      });
    }
    if (dynamicTable) {
      question.dataTable = dynamicTable;
    }
  }, [businessKeys]);

  useEffect(() => {
    const isBusinessKey = (at: IAttributeObject): boolean => {
      return businessKeys.includes(Object.keys(at.attribute)[0]);
    };

    if (dynamicTable) {
      Object.keys(dynamicTable.tableAttributes).forEach((key: string) => {
        dynamicTable.tableAttributes[key].attributes.forEach((attribute) => {
          attribute.isBusinessKey = isBusinessKey(attribute);
        });
      });
    }
  }, [dynamicTable]);

  useEffect(() => {
    if (!saveTaskAsDraftClick && tableSaved) {
      resetFlags();
    }
    if (dynamicTable) {
      removeEmptyBKsRows();
    }
  }, [saveTaskAsDraftClick]);

  useEffect(() => {
    setTableSaved(false);
    if (tableSaved) {
      changeIsNewItem(dynamicTable);
    }
  }, [tableSaved]);

  const resetFlags = () => {
    if (dynamicTable) {
      Object.keys(dynamicTable.tableAttributes).forEach((key: string) => {
        dynamicTable.tableAttributes[key].isNewItem = false;
        dynamicTable.tableAttributes[key].isModified = false;
        dynamicTable.tableAttributes[key]?.attributes.forEach((attr: IAttributeObject) => {
          attr.isModified = false;
        });
      });
      setDynamicTable(GeneralUtils.deepCopy(dynamicTable));
    }
  };

  //Get the Table Attributes by Entity Id
  const entityAtrributes = () => {
    const entityIds = dynamicTable?.entityIds;
    const tableAttributes = dynamicTable?.tableAttributes;
    let attributes: IAttributeObject[] = [];

    entityIds?.forEach((id: string) => {
      const attributesById = tableAttributes?.[id]?.attributes;
      if (attributesById) {
        attributes = attributes.concat(attributesById);
      }
    });
    return attributes;
  };

  const checkOnAddBusinessKeys = (entityId: string) => {
    let result: boolean = true;
    if (errorObject) {
      errorObject.entityIds.forEach((entityIdError) => {
        if (entityId === entityIdError) {
          result = false;
        }
      });
    }
    return result;
  };

  const checkDisabled = () => {
    return (
      isCompletedTask ||
      !hasAssignee ||
      assignee != loggedUser ||
      disabled ||
      SpelUtils.expressionValidation(question.readOnly, question.readOnlyExpression, {
        contextLocals,
        userRole: userInfo.role,
        ...locals,
      })
    );
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params);
  };

  const getColumnDefs = (table: IQuestionnaireTable | undefined) => {
    const res: Array<IDataModelColumn> = [];

    const discardButtonColumn: IDataModelColumn = {
      attributeLabel: '',
      columnType: 'TEXT',
      attributeValue: '',
      attributeName: '',
      attributeType: '',
    };

    !checkDisabled() && userCanDelete && res.push(discardButtonColumn);
    question.dataTable?.tableHeaders.map((value) => {
      if (businessKeys.find((key) => key === value.attributeName)) {
        res.push({
          attributeLabel: `${value.label}*`,
          columnType: 'TEXT',
          attributeValue: '',
          attributeName: value.attributeName,
          attributeType: '',
        });
      } else {
        res.push({
          attributeLabel: value.label,
          columnType: 'TEXT',
          attributeValue: '',
          attributeName: value.attributeName,
          attributeType: '',
        });
      }
    });
    return configureColumns(res, indexQuestion);
  };

  const getRowData = (table: IQuestionnaireTable | undefined) => {
    const rowData: Array<Record<string, string | boolean | null>> = [];
    const datatable = table;

    datatable?.entityIds.forEach((entityId) => {
      if (datatable.tableAttributes[entityId]) {
        const entityData = datatable.tableAttributes[entityId];
        const attributes = entityData.attributes;
        if (!entityData.discarded) {
          const extractedData: Record<string, string | boolean | null> = {};
          attributes.forEach((attribute) => {
            const attributeName = Object.keys(attribute.attribute)[0];
            const entityLabel = datatable.tableHeaders.find(
              (header) => header.attributeName === attributeName
            );

            if (entityLabel) {
              extractedData[entityLabel.attributeName] = attribute.attribute[attributeName];
            }
          });
          extractedData.isRecent = entityData.isNewItem;
          extractedData.isDiscarded = entityData.discarded;
          extractedData.entityId = entityId;
          extractedData.isValid = checkOnAddBusinessKeys(entityId);

          rowData.push(extractedData);
        }
      }
    });
    setRowData(rowData);
  };

  const configureColumns = (colConfigs: Array<IDataModelColumn>, questionIndex?: number) => {
    const columns: Array<ColDef> = [];
    colConfigs.forEach((c: IDataModelColumn, index: number) => {
      const checkIfBk: string | undefined = businessKeys.find((key) => c.attributeName === key);
      const column: ColDef | ColGroupDef = {
        field: c.attributeName,
        headerName: c.attributeLabel,
        filter: CONSTANTS.AG_GRID.FILTER.TEXT,
        editable: (params: any) => {
          const isNew: boolean = dynamicTable?.tableAttributes[params.data.entityId]
            .isNewItem as boolean;
          return (
            !checkDisabled() &&
            (userCanEdit || (userCanAdd && isNew)) &&
            (checkIfBk ? editable(params, isNew) : true)
          );
        },
        cellClassRules: {
          'background-color-red-50': (params: { data: { isValid: boolean } }) => {
            return !params.data.isValid;
          },
        },
      };
      if (!index && !checkDisabled() && userCanDelete) {
        column.maxWidth = 75;
        column.editable = false;
        column.cellRenderer = (params: any) => {
          return (
            <IconButton onClick={() => deleteRow(params, questionIndex)} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          );
        };
      }

      if (c.columnType) {
        switch (c.columnType.toUpperCase()) {
          case CONSTANTS.COLUMNTYPES.DATE:
            (column.filter = CONSTANTS.AG_GRID.FILTER.DATE),
              (column.cellRenderer = (params: any) => {
                let dateString;
                try {
                  dateString = dateUtils.timeZone(
                    Number(params.value) / 1000,
                    Intl.DateTimeFormat().resolvedOptions().timeZone,
                    dateFormat
                  );
                } catch (exception) {
                  dateString = '';
                }
                return <div>{dateString}</div>;
              });
            break;

          case CONSTANTS.COLUMNTYPES.CURRENCY:
            (column.filter = CONSTANTS.AG_GRID.FILTER.NUMBER),
              (column.cellRenderer = (params: any) => {
                return (
                  <div>
                    {GeneralUtils.currencyConvertorFun(Number(params.value), 'en-US', 'USD')}
                  </div>
                );
              });
            break;
        }
      }

      columns.push(column);
    });
    return columns;
  };
  const createNewRowData = (questionIndex: number, table: IQuestionnaireTable | undefined) => {
    const newData: { [key: string]: string | boolean } = {};
    const pushId = crypto.randomUUID();
    gridRef[questionIndex].current.api.columnModel.columnDefs.map((column: ColDef) => {
      if (column.field?.length) {
        newData[column.field] = '';
        newData['isNewItem'] = true;
        newData['discarded'] = false;
        newData['entityId'] = pushId;
        newData['isValid'] = true;
      }
    });

    const row: IQuestionTableAttributes = {
      isNewItem: true,
      isModified: false,
      attributes: [],
      discarded: false,
    };

    table?.tableHeaders.forEach((header, index: number) => {
      const attribute = {
        attribute: { [table.tableHeaders[index].attributeName]: '' },
        isModified: false,
        isBusinessKey: businessKeys.some((key) => key === table.tableHeaders[index].attributeName),
      };
      row.attributes.push(attribute);
    });

    dynamicTable?.entityIds.push(pushId);

    if (dynamicTable) {
      dynamicTable.tableAttributes[pushId] = row;
      setDynamicTable({ ...dynamicTable });
    }

    return newData;
  };

  const addRow = (questionIndex: number, addIndex?: number) => {
    const newItems = [createNewRowData(questionIndex, question.dataTable)];
    gridRef[questionIndex].current.api.applyTransaction({
      add: newItems,
      addIndex: addIndex,
    });
  };

  const editable = (params: any, isNew: boolean | undefined) => {
    const unchangedBks = dynamicTable?.tableAttributes[params.data.entityId].attributes.filter(
      (attribute: IAttributeObject) => {
        return attribute.isBusinessKey && !attribute.isModified;
      }
    ).length;

    return (
      isNew ||
      (unchangedBks && unchangedBks > 1) ||
      businessKeysChanged[params.data.entityId]?.includes(params.column.colId)
    );
  };

  const onCellValueChanged = (event: any) => {
    if (dynamicTable && event.oldValue != event.newValue) {
      if (!dynamicTable.tableAttributes[event.data.entityId].isNewItem) {
        dynamicTable.tableAttributes[event.data.entityId].isModified = true;
      }
      question.responseHasChanged[entity] = true;
      if (question.wasChanged) {
        question.wasChanged[entity] = true;
      }
      dynamicTable.tableAttributes[event.data.entityId].attributes.find((attribute) => {
        Object.keys(attribute.attribute).forEach((key: string) => {
          if (key === event.colDef.field) {
            attribute.attribute[key] = event.newValue;
            if (!dynamicTable.tableAttributes[event.data.entityId].isNewItem) {
              attribute.isModified = true;
            }
          }
        });
      });
    }

    businessKeys?.forEach((bk: string) => {
      if (
        bk.includes(event.colDef.field) &&
        !businessKeysChanged[event.data.entityId]?.includes(event.colDef.field)
      ) {
        businessKeysChanged[event.data.entityId] = [
          ...(businessKeysChanged[event.data.entityId] || []),
          event.colDef.field,
        ];
      }
    });
    setBusinessKeysChanged({ ...businessKeysChanged });

    if (dynamicTable) {
      question.dataTable = dynamicTable;
      GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IS_DIRTY_EVT, { isDirty: true });
      setDynamicTable({ ...dynamicTable });
    }
  };

  const deleteRow = (params: any, questionIndex?: number) => {
    gridRef[questionIndex!].current.api.applyTransaction({
      remove: [params.node.data],
    });
    const entityId: string = String(params.data.entityId);

    if (dynamicTable) {
      dynamicTable.tableAttributes[entityId].discarded = true;
      question.responseHasChanged[entity] = true;
      if (question.wasChanged) {
        question.wasChanged[entity] = true;
      }
      GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IS_DIRTY_EVT, { isDirty: true });

      if (dynamicTable.tableAttributes[entityId].isNewItem) {
        dynamicTable.entityIds.splice(dynamicTable.entityIds.indexOf(entityId));
        delete dynamicTable.tableAttributes[entityId];
      }
    }

    if (dynamicTable?.tableAttributes[entityId]) {
      const dynamicTableCopy: IQuestionnaireTable = GeneralUtils.deepCopy(dynamicTable);
      setDynamicTable(dynamicTableCopy);
      question.dataTable = dynamicTableCopy;
    }
  };

  while (gridRefs.current.length <= indexQuestion!) {
    gridRef.push(React.createRef());
  }
  return (
    <>
      {SpelUtils.expressionValidation(question.visible, question.visibilityExpression, {
        contextLocals,
        userRole: userInfo.role,
        ...locals,
      }) && (
        <Stack sx={{ width: '100%', margin: '0.5rem 0' }}>
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              style={{ width: '90%' }}>
              <h3>{question.dataTable?.tableName}</h3>
              {userCanAdd && !checkDisabled() && (
                <PlainTextButton
                  label={t('ADD_ROW')}
                  icon={<AddCircleOutlineIcon />}
                  handleClick={() => addRow(indexQuestion!)}
                  marginClass="margin-left-10"
                />
              )}
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2}>
              <div className="dynamic-table-ag-grid-container ag-theme-alpine dynamic-table-height">
                <AgGridReact
                  onGridReady={onGridReady}
                  columnDefs={getColumnDefs(question.dataTable)}
                  defaultColDef={defaultColumnDef}
                  rowData={rowData}
                  animateRows={true}
                  ref={gridRef[indexQuestion!]}
                  singleClickEdit={true}
                  tabToNextCell={(params) => null}
                  onCellValueChanged={onCellValueChanged}
                  stopEditingWhenCellsLoseFocus={true}
                  getContextMenuItems={() => CONSTANTS.AG_GRID.CONTEXT_MENU_ITEMS}
                />
              </div>
              <Grid className="questionnaire-icons">
                <IdAndVStatus question={question} entity={entity} value={undefined} />
                <QuestionTooltip question={question} />
              </Grid>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2}>
              {error && errorObject && <ErrorMessage errorMessage={t('ROWS_HIGHLIGHTED_ERROR')} />}
            </Stack>
          </>
        </Stack>
      )}
    </>
  );
};

export default DynamicTableQuestion;
