import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  Divider,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  Select,
  Switch,
  Tooltip,
} from '@mui/material';
import SettingsIcon from '../../assets/icons/settings.svg';
import { availableLanguages } from '../../utils/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from '../../utils/constants/constants';
import LocalStorageUtils from '../../utils/functions/localStorageUtils';
import GeneralUtils from '../../utils/functions/generalUtils';
import { EVENTS_CONSTANTS } from '../../utils/constants/event_constants';
import React, { useState } from 'react';
import { PermissionTypes } from '../../utils/entities/role/IPermission';

const SettingMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [dateFormat, setDateFormat] = useState<string>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT) ??
      window.__RUNTIME_CONFIG__?.REACT_APP_DEFAULT_DATE_FORMAT
  );
  const [taskView, setTaskView] = useState<boolean>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TASK_VIEW) ?? false
  );
  const [routing, setRouting] = useState<boolean>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.ROUTING) ?? false
  );

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    changeDateFormat(dateFormat);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t, i18n } = useTranslation();

  const changeDateFormat = (format: string) => {
    setDateFormat(format);
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT, format);
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.DATE_FORMAT_CHANGE, format);
  };

  const changeTaskView = (view: boolean) => {
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TASK_VIEW, view);
    setTaskView(view);
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.TASK_VIEW_CHANGE, view);
  };

  const changeRouting = (routingValue: boolean) => {
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.ROUTING, routingValue);
    setRouting(routingValue);
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.ROUTING_CHANGE, routingValue);
  };

  return (
    <div className="user-settings">
      <IconButton
        color="inherit"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <Icon>
          <img alt="settings" src={SettingsIcon} height={25} width={25} />
        </Icon>
      </IconButton>

      <Menu
        id="basic-menu"
        slotProps={{ paper: { className: 'nexus-shadow setting-menu' } }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <div>
          <div className="margin-left-10">
            <h3>{t('SETTINGS')}</h3>
          </div>
          <Divider className="thicker-divider" />
          {GeneralUtils.checkUserPermissions(PermissionTypes.LANGUAGE) && (
            <MenuItem data-testid="language-menu-item">
              {t('LANGUAGE')}:
              <FormControl className="langu-display" size="small">
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                  defaultValue={i18n.language}
                  onChange={(e) => i18n.changeLanguage(e.target.value)}>
                  {availableLanguages.map((language: string) => (
                    <MenuItem key={language} value={language}>
                      {language}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MenuItem>
          )}
          {
            <MenuItem data-testid="date-menu-item">
              {t('DATE_FORMAT')}:
              <FormControl className="langu-display" size="small">
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                  value={dateFormat}
                  onChange={(e) => changeDateFormat(String(e.target.value))}>
                  {CONSTANTS.DATES.DATE_FORMATS.map((format: string, index: number) => (
                    <MenuItem key={index} value={format}>
                      {format}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MenuItem>
          }
          <MenuItem data-testid="task-menu-item">
            {t('TASK_VIEW')}:
            <FormControl className="langu-display" size="small">
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Tooltip title={t('TASK_VIEW_TOOLTIP')}>
                <Switch
                  checked={taskView}
                  onChange={(event) => {
                    changeTaskView(event.target.checked);
                  }}
                />
              </Tooltip>
            </FormControl>
          </MenuItem>
          <MenuItem data-testid="routing-menu-item">
            {t('ROUTING')}:
            <FormControl className="langu-display" size="small">
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Tooltip title={t('ROUTING_TOOLTIP')}>
                <Switch
                  checked={routing}
                  onChange={(event) => {
                    changeRouting(event.target.checked);
                  }}
                />
              </Tooltip>
            </FormControl>
          </MenuItem>
        </div>
      </Menu>
    </div>
  );
};

export default SettingMenu;
