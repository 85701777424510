import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import { CONSTANTS } from '../../../utils/constants/constants';
import { IAccessToken } from '../../../utils/entities/authentication';
import { IPermission, PermissionTypes } from '../../../utils/entities/role';
import GeneralUtils from '../../../utils/functions/generalUtils';
import ICaseRoles from '../../../utils/entities/case/ICaseRoles';
import PlainTextButton from '../../generic/button/PlainTextButton';
import AssignCaseModal from '../assign-case-modal/AssignCaseModal';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { GQL_CASE_ROLES } from '../../../service/graphql/case-roles.service';
import { EVENTS_CONSTANTS } from '../../../utils/constants/event_constants';
import { caseRolesRefresh } from '../../../service/case-manager.service';

interface IProps {
  caseId: string;
  caseRoles: ICaseRoles[];
}

const AssignCaseContainer = ({ caseId, caseRoles }: IProps) => {
  const { t } = useTranslation();
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const projectName: string = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );

  const [casePermissions, setCasePermissions] = useState<Array<IPermission>>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS)
  );

  const isUserAdmin: boolean = GeneralUtils.checkUserPermissions(
    PermissionTypes.CASE_ROLES_ADMIN,
    casePermissions
  );
  const canUserViewCaseRoles: boolean = GeneralUtils.checkUserPermissions(
    PermissionTypes.AGGREGATE_VIEW_CASE_ROLES,
    casePermissions
  );
  const canUserEdit: boolean = GeneralUtils.checkUserPermissions(
    PermissionTypes.AGGREGATE_EDIT_CASE_ROLES,
    casePermissions
  );
  const canUserSelfEdit: boolean = GeneralUtils.checkUserPermissions(
    PermissionTypes.AGGREGATE_SELF_EDIT_CASE_ROLES,
    casePermissions
  );

  const checkIfUserCanViewCaseRoles = (): boolean => {
    return isUserAdmin || canUserViewCaseRoles || canUserEdit || canUserSelfEdit;
  };

  const { data: caseRolesData } = GQL_CASE_ROLES(caseId);

  const [openAssignCase, setOpenAssignCase] = useState<boolean>(false);
  const [assignedCaseRoles, setAssignedCaseRoles] = useState<ICaseRoles[]>([]);
  const [originalCaseRoles, setOriginalCaseRoles] = useState<ICaseRoles[]>([]);
  const [isDataUpdated, setIsDataUpdated] = useState<boolean>(false);
  const [isCaseRolesAdmin, setIsCaseRolesAdmin] = useState<boolean>(false);
  const [isCaseRolesEditable, setIsCaseRolesEditable] = useState<boolean>(false);
  const [isCaseRolesSelfEditable, setIsCaseRolesSelfEditable] = useState<boolean>(false);
  const [userCanViewCaseRoles, setUserCanViewCaseRoles] = useState<boolean>(
    checkIfUserCanViewCaseRoles()
  );

  useEffect(() => {
    if (isDataUpdated) {
      checkPermissions();
      isRoleEditable();
    }
  }, [isDataUpdated, isUserAdmin, canUserEdit, canUserSelfEdit, caseRoles]);

  useEffect(() => {
    if (caseRolesData) {
      updateCaseRoles(caseRolesData);
    }
  }, [caseRolesData]);

  const caseRolesRefreshListener = (e: MessageEvent) => {
    if (e.data) {
      const updatedCaseRoles: Array<ICaseRoles> = JSON.parse(e.data);
      updateCaseRoles(updatedCaseRoles);
    }
  };

  GeneralUtils.sseEvent(EVENTS_CONSTANTS.CASE_ROLES_UPDATE, caseRolesRefresh(caseId), true, {
    case_roles_update: caseRolesRefreshListener,
  });

  const updateCaseRoles = (caseRoles: Array<ICaseRoles>) => {
    if (caseRoles) {
      if (caseRoles.length) {
        const filteredCaseRoles: Array<ICaseRoles> = caseRoles.filter(
          (role: ICaseRoles) => role.project === projectName
        );
        setAssignedCaseRoles(structuredClone(filteredCaseRoles));
        setOriginalCaseRoles(structuredClone(filteredCaseRoles));
      } else {
        LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS);
      }

      setIsDataUpdated(true);
    }
  };

  const checkPermissions = () => {
    const actPermissions: Array<IPermission> = GeneralUtils.getCasePermissionsFromCaseRoles(
      assignedCaseRoles,
      caseRoles,
      userInfo.user.toLowerCase()
    );
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS, actPermissions);
    setCasePermissions(actPermissions);
    setUserCanViewCaseRoles(checkIfUserCanViewCaseRoles());
  };

  const isRoleEditable = () => {
    setIsCaseRolesAdmin(isUserAdmin);
    setIsCaseRolesEditable(canUserEdit);
    setIsCaseRolesSelfEditable(canUserSelfEdit);
  };

  return (
    <>
      {caseRoles.length > 0 && userCanViewCaseRoles && (
        <PlainTextButton
          icon={<AssignmentIndIcon />}
          label={t('CASE_ROLES.CASE_ROLES')}
          handleClick={() => setOpenAssignCase(true)}
        />
      )}
      <AssignCaseModal
        isCaseRolesAdmin={isCaseRolesAdmin}
        isCaseRolesEditable={isCaseRolesEditable}
        isCaseRolesSelfEditable={isCaseRolesSelfEditable}
        originalCaseRoles={originalCaseRoles}
        assignedCaseRoles={assignedCaseRoles}
        setAssignedCaseRoles={setAssignedCaseRoles}
        isOpen={openAssignCase}
        closeDlg={() => {
          setOpenAssignCase(false);
          setAssignedCaseRoles(structuredClone(originalCaseRoles));
        }}
        caseId={caseId}
      />
    </>
  );
};

export default AssignCaseContainer;
