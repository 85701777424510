import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import './narrative.scss';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import GeneralUtils from '../../../utils/functions/generalUtils';
import {
  IVersionItem,
  INarrative,
  INarrativeVersionDetail,
  INarrativeTemplateProps,
} from '../../../utils/entities/narrative';
import {
  generateNarrative,
  getTemplateContentByVersionId,
  updateNarrative,
  resetNarrativeByVersion,
  saveNarrativeToFile,
  deleteNarrative,
} from '../../../service/narrative-generation.service';
import { useIScreen } from '../../../pages/task/Task';
import DefaultModal from '../../generic/modal/Modal';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DropdownComponent from '../../generic/dropdown/Dropdown';
import { addAlert } from '../../../store/actions/alerts.actions';
import DarkButton from '../../generic/buttons/DarkButton';
import VerticalDivider from '../../generic/divider/VerticalDivider';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import { CONSTANTS } from '../../../utils/constants/constants';
import { IPermission, PermissionTypes } from '../../../utils/entities/role/IPermission';
import GenerateAndTimeStamp from './GenerateAndTimeStamp';
import VersionListTemplate from './VersionListTemplate';
import ConfirmationModal from '../../generic/modal/ConfirmationModal';

const NarrativeTemplate = (props: INarrativeTemplateProps) => {
  const {
    templateList,
    openedNarrativeVersion,
    setOpenedNarrativeVersion,
    narrativeText,
    setNarrativeText,
    checkIfChanged,
    setShowMainLoader,
    setGenerating,
    clearNarrativeId,
    selectedTemplate,
    setSelectedTemplate,
    tabType,
    openVersionDropdown,
    setOpenVersionDropdown,
    checkRestore,
    setCheckRestore,
    versionList,
    getVersionsByTemplate,
    setCheckInputClick,
  } = { ...props };
  const { data, isCompletedTask, hasAssignee, assignee, loggedUser } = useIScreen();
  const { t } = useTranslation();
  const casePermissions: IPermission[] = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS
  );
  const canSaveToPdf = GeneralUtils.checkUserPermissions(
    PermissionTypes.NARRATIVE_SAVE_TO_PDF,
    casePermissions
  );

  const [chosenTemplate, setChosenTemplate] = useState<string | undefined>();
  const [selectedVersion, setSelectedVersion] = useState<IVersionItem | undefined>();
  const [selectedVersionText, setSelectedVersionText] = useState<string>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [versionToEdit, setVersionToEdit] = useState<string | undefined>();
  const [templateToEdit, setTemplateToEdit] = useState<string | undefined>();
  const [narrativeTextToEdit, setNarrativeTextToEdit] = useState<string | undefined>();
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [editAndReset, setEditAndReset] = useState<boolean>(false);
  const [editAndGenerate, setEditAndGenerate] = useState<boolean>(false);
  const [templateWIP, setTemplateWIP] = useState<IVersionItem | undefined>();
  const [deleteVersionId, setDeleteVersionId] = useState<string | undefined>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    setTemplateWIP(versionList.find((ele: IVersionItem) => ele.version == 'WIP'));
  }, [versionList]);

  const checkDisabled = () => {
    return isCompletedTask || !hasAssignee || assignee != loggedUser;
  };

  const getVersionContent = (versionId: string) => {
    setShowMainLoader(true);
    getTemplateContentByVersionId(versionId)
      .then((res: INarrativeVersionDetail) => {
        setOpenedNarrativeVersion(res);
        setNarrativeText(res.content ? res.content : '');
      })
      .catch((error) => console.error('ERROR:', error))
      .finally(() => {
        setShowMainLoader(false);
      });
  };

  const updateNarrativeVersion = (
    templateVersionId: string | undefined,
    content: string | undefined
  ) => {
    //When updating the WIP version, it creates a new version equal to the last WIP
    if (openedNarrativeVersion && templateVersionId) {
      updateNarrative(templateVersionId, {
        templateId: templateToEdit,
        content: content,
        timeZone: openedNarrativeVersion.timeZone,
        caseId: openedNarrativeVersion.caseId,
        version: 'WIP',
        narrativeGeneratedTime: openedNarrativeVersion.narrativeGeneratedTime
          ? openedNarrativeVersion.narrativeGeneratedTime
          : undefined,
        tabType: tabType,
      })
        .then(() => {
          if (editAndReset) {
            setShowMainLoader(true);
            resetNarrative(templateVersionId);
          }

          if (editAndGenerate) {
            generateNewNarrative(templateWIP?.id);
          }
          addAlert({
            type: 'success',
            primaryText: t('NARRATIVE_SUCCESS'),
          });
        })
        .catch((error: string) => {
          if (error) {
            addAlert({
              type: 'error',
              primaryText: t('ERROR_MESSAGE'),
            });
          }
        })
        .finally(() => {
          setShowMainLoader(false);
          setCheckInputClick(false);
        });
    }
  };

  const resetNarrative = (id: string | undefined) => {
    if (id) {
      resetNarrativeByVersion(id)
        .then(() => {
          addAlert({
            type: 'success',
            primaryText: t('NARRATIVE_RESET'),
          });
          setEditAndReset(false);
        })
        .catch((error: string) => {
          if (error) {
            addAlert({
              type: 'error',
              primaryText: t('ERROR_MESSAGE'),
            });
          }
        });
    }
  };

  const generateNewNarrative = (id: string | undefined) => {
    if (id) {
      generateNarrative(id)
        .then((version: INarrativeVersionDetail) => {
          setSelectedVersion(version);
          setEditAndGenerate(false);
        })
        .catch(() => addAlert({ type: 'error', primaryText: t('ERROR') }));
    }
  };

  const editSelectedNarrative = (versionId: string | undefined, content: string | undefined) => {
    updateNarrativeVersion(versionId, clearNarrativeId(content));
  };

  useEffect(() => {
    templateList?.forEach((template: INarrative) => {
      if (template.id === selectedTemplate) {
        getVersionsByTemplate(template.name);
      }
    });
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedTemplate && selectedVersion?.id) {
      getVersionContent(selectedVersion?.id);
    }
  }, [selectedVersion]);

  useEffect(() => {
    const selectedVersion = versionList?.find((ele: IVersionItem) => ele.version == 'WIP');
    setSelectedVersionText(selectedVersion?.version ? selectedVersion?.version : '');
    if (selectedVersion) {
      setSelectedVersion(selectedVersion);
    }
  }, [versionList]);

  const handleTooltipOpen = () => {
    selectedTemplate && setOpenVersionDropdown(true);
  };

  const handleTooltipClose = () => {
    setOpenVersionDropdown(false);
  };

  const handleTemplateChange = (value: string) => {
    setVersionToEdit(selectedVersion?.id);
    setTemplateToEdit(selectedVersion?.templateId);
    setNarrativeTextToEdit(narrativeText);
    if (checkIfChanged) {
      setShowConfirmationModal(true);
      setChosenTemplate(value);
    } else {
      setSelectedTemplate(value);
      setChosenTemplate(undefined);
    }
  };

  const setTemplateIfChosen = (chosenOne: string | undefined) => {
    if (chosenOne) {
      setSelectedTemplate(chosenOne);
      setChosenTemplate(undefined);
    }
  };

  const saveToCase = () => {
    saveNarrativeToFile({
      templateVersionId: templateWIP?.id ?? '',
      caseId: data.caseId,
      fileName: `${data.caseId}-${openedNarrativeVersion?.templateName}-${openedNarrativeVersion?.version}.pdf`,
      expirationDate: 0,
    })
      .then(() => addAlert({ type: 'success', primaryText: t('FILE_SAVED') }))
      .catch((error) => addAlert({ type: 'error', primaryText: error }));
  };

  const deleteVersionById = (versionId: string) => {
    setDeleteVersionId(versionId);
    setShowDeleteModal(true);
    handleTooltipClose();
  };

  const deleteVersion = () => {
    if (deleteVersionId) {
      deleteNarrative(deleteVersionId)
        .then(() => {
          addAlert({
            type: 'success',
            primaryText: t('NARRATIVE_DELETED'),
          });
          templateList?.forEach((template: INarrative) => {
            if (template.id === selectedTemplate) {
              getVersionsByTemplate(template.name);
            }
          });
        })
        .catch((error: string) => {
          if (error) {
            addAlert({
              type: 'error',
              primaryText: t('ERROR_MESSAGE'),
            });
          }
        });
    }
  };

  useEffect(() => {
    if (checkRestore) {
      const version = versionList.find((version) => version.version === 'WIP');
      setSelectedVersion(version);
      setSelectedVersionText(version?.version);
      setCheckRestore(false);
    }
  }, [checkRestore]);

  return (
    <>
      <ConfirmationModal
        showConfirmButton
        openModal={showDeleteModal}
        setOpenModal={setShowDeleteModal}
        action={deleteVersion}
        content={{
          title: t('DELETE_VERSION'),
          text: t('CONFIRM_DELETE_VERSION'),
        }}
      />

      <Grid container rowSpacing={1} className="narrative-template-container">
        <DefaultModal
          open={showConfirmationModal}
          className="confirmation-modal"
          title={t('SAVE_CHANGES_TITLE')}
          body={<p>{t('SAVE_CHANGES')}</p>}
          setConfirm={() => {
            editSelectedNarrative(versionToEdit, narrativeTextToEdit);
            setShowConfirmationModal(false);
            setTemplateIfChosen(chosenTemplate);
            setShowMainLoader(true);
          }}
          onClose={() => {
            setShowConfirmationModal(false);
            setTemplateIfChosen(chosenTemplate);
          }}
          confirmText={t('YES')}
          denyText={t('NO')}
        />
        {checkIfChanged ? (
          <DefaultModal
            open={showResetModal}
            className="confirmation-modal"
            title={editAndGenerate ? t('GENERATE_MODAL_TITLE') : t('RESET_MODAL_TITLE')}
            body={
              <p>
                {editAndGenerate ? t('GENERATE_MODAL_BODY_CHANGES') : t('RESET_MODAL_BODY_CHANGES')}
              </p>
            }
            setConfirm={() => {
              editSelectedNarrative(templateWIP?.id, narrativeText);
              setShowResetModal(false);
            }}
            onClose={() => {
              editAndGenerate
                ? generateNewNarrative(templateWIP?.id)
                : resetNarrative(templateWIP?.id);
              setShowResetModal(false);
            }}
            setCancel={() => {
              setEditAndGenerate(false);
              setShowResetModal(false);
            }}
            confirmText={t('YES')}
            denyText={t('No')}
            cancelText={t('CANCEL')}
          />
        ) : (
          <DefaultModal
            open={showResetModal}
            className="confirmation-modal"
            title={editAndGenerate ? t('GENERATE_MODAL_TITLE') : t('RESET_MODAL_TITLE')}
            body={<p>{editAndGenerate ? t('GENERATE_MODAL_BODY') : t('RESET_MODAL_BODY')}</p>}
            setConfirm={() => {
              editAndGenerate
                ? generateNewNarrative(templateWIP?.id)
                : resetNarrative(templateWIP?.id);
              setShowResetModal(false);
            }}
            onClose={() => {
              setEditAndGenerate(false);
              setShowResetModal(false);
            }}
            confirmText={t('YES')}
            denyText={t('CANCEL')}
          />
        )}
        <Grid item xs={6} className="narrative-dropdown">
          <Tooltip title={t('GENERATE_MODAL_TITLE')}>
            <span>
              <IconButton
                className="narrative-generate-button"
                disabled={!openedNarrativeVersion}
                onClick={() => {
                  setGenerating(true);
                  setEditAndGenerate(true);
                  setShowResetModal(true);
                  setShowMainLoader(false);
                }}>
                <PlayCircleOutlineIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('RESET_MODAL_TITLE')}>
            <span>
              <IconButton
                className="reset-button"
                onClick={() => {
                  if (checkIfChanged) {
                    setEditAndReset(true);
                  }
                  setShowResetModal(true);
                }}
                disabled={!openedNarrativeVersion}>
                <RestartAltIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Box sx={{ minWidth: 120 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="template-dropdown-label">
                {t('NARRATIVE.SELECT_NARRATIVE')}
              </InputLabel>
              <Select
                labelId="template-dropdown-label"
                id="template-dropdown-standard"
                className="template-dropdown"
                value={selectedTemplate}
                disabled={checkDisabled()}
                onChange={(e: SelectChangeEvent) => {
                  handleTemplateChange(e.target.value);
                }}
                label={t('NARRATIVE.SELECT_NARRATIVE')}>
                {templateList?.map((template: INarrative) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {selectedTemplate && (
          <Grid item className="align-item-center">
            <DropdownComponent
              setClickAway={handleTooltipClose}
              className="version-wrapper"
              setClose={handleTooltipClose}
              setOpen={handleTooltipOpen}
              open={openVersionDropdown}
              list={
                <VersionListTemplate
                  versionList={versionList}
                  checkIfChanged={checkIfChanged}
                  setVersionToEdit={setVersionToEdit}
                  setTemplateToEdit={setTemplateToEdit}
                  setNarrativeTextToEdit={setNarrativeTextToEdit}
                  setShowConfirmationModal={setShowConfirmationModal}
                  selectedVersion={selectedVersion}
                  narrativeText={narrativeText}
                  setSelectedVersion={setSelectedVersion}
                  setSelectedVersionText={setSelectedVersionText}
                  templateList={templateList}
                  getVersionsByTemplate={getVersionsByTemplate}
                  selectedTemplate={selectedTemplate}
                  handleTooltipClose={handleTooltipClose}
                  deleteVersionById={deleteVersionById}
                  checkRestore={checkRestore}
                  setCheckRestore={setCheckRestore}
                />
              }
              label={t('VERSION')}
              selectedOption={selectedVersionText ?? ''}
            />
          </Grid>
        )}
        <Grid item className="align-item-center narrative-timestamp">
          {openedNarrativeVersion?.narrativeGeneratedTime && (
            <GenerateAndTimeStamp openedNarrativeVersion={openedNarrativeVersion} />
          )}
        </Grid>
        {selectedTemplate && canSaveToPdf && (
          <Grid item className="align-item-center">
            <VerticalDivider />
            <DarkButton text={t('SAVE_TO_CASE')} onClick={saveToCase} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default NarrativeTemplate;
