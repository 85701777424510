import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CONSTANTS } from '../../../../../utils/constants/constants';
import PrimaryButton from '../../../../generic/buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { ILinkCase } from '../../../../../utils/entities/case/CaseHeader/ILinkCase';
import GeneralUtils from '../../../../../utils/functions/generalUtils';
import { PermissionTypes } from '../../../../../utils/entities/role/IPermission';
import { createLinkCase, getLinkedCasesList } from '../../../../../service/case-manager.service';
import { ILink } from '../../../../../utils/entities/case/ILink';
import moment from 'moment';
import { addAlert } from '../../../../../store/actions/alerts.actions';
import LinkedCasesTable from './linked-cases-table/LinkedCasesTable';
import CaseLink from './CaseLink';
import { ICaseLinkContainerProps } from '../../../../../utils/entities/case/CaseHeader/case-link/ICaseLink';
import LocalStorageUtils from '../../../../../utils/functions/localStorageUtils';
import { ISearchCase } from '../../../../../utils/entities/case/ISearchCase';

const CaseLinkContainer = ({
  showModal,
  setShowModal,
  caseId,
  caseDisplayId,
  dateFormat,
  userInfo,
  internalStatus,
}: ICaseLinkContainerProps) => {
  const { t } = useTranslation();
  const canLinkCases = GeneralUtils.checkUserPermissions(PermissionTypes.LINK_CASES_CREATE);
  const timeZone: string = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE);

  const [valueInfo, setValueInfo] = useState<string>('');
  const [isLinkedCasesLoading, setIsLinkedCasesLoading] = useState<boolean>(true);
  const [linkCaseDetails, setLinkCaseDetails] = useState<Array<ILinkCase>>([]);
  const [typedCase, setTypedCase] = useState<string>('');
  const [cases, setCases] = useState<ISearchCase | null>(null);
  const [linkCaseSelected, setLinkCaseSelected] = useState<number>(0);

  useEffect(() => {
    retrieveLinks(caseId);
  }, []);

  const linkingCase = async () => {
    const current = new Date();
    const request: ILink = {
      caseId: caseId,
      linkCaseId: linkCaseSelected,
      relation: valueInfo,
      createdBy: userInfo.user,
      createdOn: moment.tz(current, timeZone).format(CONSTANTS.DATE_FORMATE),
    };

    createLinkCase(request)
      .then(() => {
        setLinkCaseSelected(0);
        setValueInfo('');
        setCases(null);
        retrieveLinks(caseId);
        addAlert({
          type: 'success',
          primaryText: t('CASE_LINK.LINKED'),
        });
      })
      .catch(() => {
        addAlert({
          type: 'error',
          primaryText: t('ERROR_MESSAGE'),
        });
      });
  };

  const retrieveLinks = (caseId: string | undefined) => {
    setIsLinkedCasesLoading(true);
    getLinkedCasesList(caseId)
      .then((caseLink: Array<ILinkCase>) => {
        setLinkCaseDetails(caseLink);
      })
      .finally(() => {
        setIsLinkedCasesLoading(false);
      });
  };

  const closeLinkCasesDlg = () => {
    setShowModal(false);
    setLinkCaseSelected(0);
    setValueInfo('');
    setCases(null);
    setTypedCase('');
  };

  return (
    <Dialog fullWidth={true} maxWidth={'xl'} open={showModal} onClose={() => closeLinkCasesDlg()}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box
            flexGrow={
              1
            }>{`${t('CASE_LINK.TITLE')} (${GeneralUtils.getCaseDisplayId(caseId, caseDisplayId)})`}</Box>
          <Box>
            <Button onClick={() => closeLinkCasesDlg()} className="color-secondary">
              <CloseIcon />
            </Button>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <LinkedCasesTable
          retrieveLinks={retrieveLinks}
          linkCaseDetails={linkCaseDetails}
          timeZone={timeZone}
          caseId={caseId}
          dateFormat={dateFormat}
          isLinkedCasesLoading={isLinkedCasesLoading}
        />

        <CaseLink
          valueInfo={valueInfo}
          setValueInfo={setValueInfo}
          internalStatus={internalStatus}
          typedCase={typedCase}
          setTypedCase={setTypedCase}
          cases={cases}
          setCases={setCases}
          setLinkCaseSelected={setLinkCaseSelected}
        />
      </DialogContent>
      <DialogActions className="upload-dialog-action">
        {canLinkCases && (
          <PrimaryButton
            text={t('CASE_LINK.LINK')}
            onClick={() => {
              linkingCase().then(() => setTypedCase(''));
            }}
            isDisabled={!linkCaseSelected || !valueInfo || !userInfo}
            isPositiveButton
          />
        )}
        <PrimaryButton text={t('CANCEL')} onClick={closeLinkCasesDlg} />
      </DialogActions>
    </Dialog>
  );
};

export default CaseLinkContainer;
