import React, { useEffect, useState } from 'react';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  RadialLinearScale,
  registerables,
  Tooltip,
} from 'chart.js';
import { Grid } from '@mui/material';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  GQL_DASHBOARD_WORKLOAD_CARD_DATA,
  GQL_CHARTS_DATA,
} from '../../../service/graphql/dashboard-workload.service';
import { CONSTANTS } from '../../../utils/constants/constants';
import { IChart } from '../../../utils/entities/chart/IChart';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import { useTranslation } from 'react-i18next';
import SliderCarousel from '../../generic/slider/SliderCarousel';
import ChartContainer from '../../chart/ChartContainer';
import './ChartCarousel.scss';
import GeneralUtils from '../../../utils/functions/generalUtils';
import TaskListContext from '../../../contexts/TaskListContext';
import { EVENTS_CONSTANTS } from '../../../utils/constants/event_constants';
import IAgGridTblPayload from '../../../utils/entities/ag-grid/IAgGridTblPayload';
import { IRole, PermissionTypes } from '../../../utils/entities/role';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  PointElement,
  RadialLinearScale,
  ArcElement,
  ChartDataLabels,
  Tooltip,
  Legend,
  ...registerables
);

const ChartDisplay = () => {
  const { t } = useTranslation();
  const [activeRole, setActiveRole] = useState<IRole>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER.ACTIVE_ROLE)
  );
  const canSeeCards = GeneralUtils.checkUserPermissions(PermissionTypes.CHART_CARD);
  const {
    data: cardData,
    loading: cardLoading,
    error: cardError,
  } = GQL_DASHBOARD_WORKLOAD_CARD_DATA(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT),
    !canSeeCards
  );

  const [charts, setCharts] = useState<IChart[]>([]);
  const [taskListPayload, setTaskListPayload] = useState<IAgGridTblPayload | undefined>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TASK_LIST_PAYLOAD)
  );

  const {
    data: chartData,
    loading: chartLoading,
    error: chartError,
    refetch: chartRefetch,
  } = GQL_CHARTS_DATA(CONSTANTS.CHART.REQUEST, taskListPayload);

  document.addEventListener(EVENTS_CONSTANTS.TASK_LIST_PAYLOAD_UPDATED, (e: any) => {
    setTaskListPayload(GeneralUtils.deepCopy(e.detail));
  });

  document.addEventListener(EVENTS_CONSTANTS.ACTIVE_ROLE_UPDATED, (e: any) => {
    setActiveRole(GeneralUtils.deepCopy(e.detail));
  });

  useEffect(() => {
    const newCharts: IChart[] = [];

    const charts = chartData?.chartsQuery ?? [];
    if (!chartError && !!charts.length) {
      for (const data of charts) {
        newCharts.push({
          data: data,
          chartType: data.type,
          loading: chartLoading,
        });
      }
    }
    if (!chartError && chartLoading && !charts.length) {
      newCharts.push({
        data: undefined,
        chartType: undefined,
        loading: chartLoading,
      });
    }

    if (!cardError && canSeeCards) {
      newCharts.push({
        data: cardData?.cardViewDetails,
        loading: cardLoading,
        chartType: 'cards',
      });
    }

    setCharts(newCharts);
  }, [chartData, cardData, activeRole]);

  useEffect(() => {
    chartRefetch();
  }, [activeRole]);

  return (
    <>
      {taskListPayload ? (
        <TaskListContext.Provider value={taskListPayload}>
          <Grid container spacing={2} className="chart-carousel">
            {cardError == undefined || chartError == undefined ? (
              <SliderCarousel
                toShow={charts.map((chart, chartIndex) => (
                  <ChartContainer
                    data={chart.data}
                    chartType={chart.chartType}
                    loading={chart.loading}
                    isDatePickerEnabled={chart.isDatePickerEnabled}
                    key={chartIndex}
                  />
                ))}
              />
            ) : (
              <p>{t('ERROR_FETCHING')}</p>
            )}
          </Grid>
        </TaskListContext.Provider>
      ) : (
        <p>{t('ERROR_FETCHING')}</p>
      )}
    </>
  );
};

export default ChartDisplay;
