import React, { useEffect, useState } from 'react';
import { Button, Switch, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IAboutPage, IAboutPageConfigProps } from '../../utils/entities/screen/IAboutPage';
import DefaultModal from '../../components/generic/modal/Modal';
import { getProjSecurityAttributes } from '../../service/config-manager.service';
import { CONSTANTS } from '../../utils/constants/constants';
import TextFieldComponent from '../../components/generic/inputs/text-field/TextFieldComponent';
import GeneralUtils from '../../utils/functions/generalUtils';
import { PermissionTypes } from '../../utils/entities/role/IPermission';
import { useNavigate } from 'react-router-dom';
import AutocompleteComponent from '../../components/generic/inputs/autocomplete/AutocompleteComponent';
import AutocompleteValue from '../../types/autocompleteValue';
import IAttribute from '../../utils/entities/genericComponents/IAtribute';
import { addAlert } from '../../store/actions/alerts.actions';
import LocalStorageUtils from '../../utils/functions/localStorageUtils';
import { IAccessToken } from '../../utils/entities/authentication';
import { useCookies } from 'react-cookie';

const AboutPageConfigs = (props: IAboutPageConfigProps) => {
  const {
    selectedPageId,
    pages,
    isNewPage,
    setPages,
    getPageById,
    deletePage,
    resetPages,
    handleSubmit,
  } = { ...props };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [assetsCookies] = useCookies([CONSTANTS.COOKIES.ASSETS]);

  const [selectedPage, setSelectedPage] = useState<IAboutPage>(getPageById(pages, selectedPageId));
  const [assetOpts, setAssetOpts] = useState<Array<string>>([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [userCanAccess, setUserCanAccess] = useState<boolean>(
    !!assetsCookies.assets.assets.length &&
      GeneralUtils.checkUserPermissions(PermissionTypes.ABOUT_PAGE_CONFIG)
  );
  const projectName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );

  useEffect(() => {
    getProjSecurityAttributes(projectName).then((assets) => {
      setAssetOpts(assets.map((asset) => asset.name)[0]);
    });
  }, []);

  useEffect(() => {
    setSelectedPage(getPageById(pages, selectedPageId));
  }, [pages, selectedPageId]);

  useEffect(() => {
    setTimeout(() => {
      setUserCanAccess(
        !!assetsCookies.assets.assets.length &&
          GeneralUtils.checkUserPermissions(PermissionTypes.ABOUT_PAGE_CONFIG)
      );
    }, 5000);
  }, [userCanAccess]);

  const getAllPageNames = () => {
    const names: Array<IAttribute> = [];
    const getPageName = (pages: Array<IAboutPage>) => {
      for (const page of pages) {
        if (page.pageId !== selectedPageId) {
          names.push({
            value: page.pageId.toString(),
            name: page.pageName,
          });
        }

        getPageName(page.children);
      }
    };
    getPageName(pages);

    return names;
  };
  const pageOpts = getAllPageNames();

  const handlePageOrderChanged = (order: number) => {
    const val = order >= 0 ? order : 0;
    const page = getPageById(pages, selectedPageId);
    page ? (page.pageOrder = val) : null;
    setPages([...pages]);
  };

  const handlePageNameChanged = (newName: string) => {
    const page = getPageById(pages, selectedPageId);
    page ? (page.pageName = newName) : null;
    setPages([...pages]);
  };

  const handleDefaultPageChanged = () => {
    const page = getPageById(pages, selectedPageId);
    page ? (page.isDefault = !page.isDefault) : null;
    setPages([...pages]);
  };

  const handleAssetsChanged = (val: AutocompleteValue | AutocompleteValue[]) => {
    const page = getPageById(pages, selectedPageId);
    page ? (page.assets = val as Array<string>) : null;
    setPages([...pages]);
  };

  const handleParentPageChanged = (val: AutocompleteValue | AutocompleteValue[]) => {
    const page = getPageById(pages, selectedPageId);
    const newParentId = val ? Number((val as IAttribute).value) : null;
    page ? (page.parentPageId = newParentId) : null;
    setPages([...pages]);
  };

  const handleReset = () => {
    resetPages();
  };

  const handleDelete = () => {
    deletePage();
  };

  if (!userCanAccess) {
    navigate(CONSTANTS.PAGES_URL.INBOX);
    addAlert({
      type: 'error',
      primaryText: t('ACCESS_NOT_GRANTED'),
    });
  }

  return (
    <>
      <Grid direction="row" container alignItems="center" justifyContent="space-between">
        <Grid container xs={5} sm={6} md={6} lg={7} xl={8} direction="row" spacing={1}>
          <Grid item xs={6} md={4} xl={2} lg={4}>
            <TextFieldComponent
              value={selectedPage.pageName}
              label={t('TITLE')}
              type="text"
              setData={handlePageNameChanged}
            />
          </Grid>

          <Grid item xs={6} md={4} xl={2} lg={4}>
            <AutocompleteComponent
              value={{
                value: getPageById(pages, selectedPage.parentPageId ?? -1)?.pageId?.toString(),
                name: getPageById(pages, selectedPage.parentPageId ?? -1)?.pageName,
              }}
              setData={handleParentPageChanged}
              options={pageOpts}
              getOptionLabel={(option) => (option as IAttribute).name ?? ''}
              label={t('PARENT_PAGE')}
            />
          </Grid>

          <Grid item xs={6} md={4} xl={2} lg={4}>
            <TextFieldComponent
              value={selectedPage.pageOrder}
              label={t('PAGE_ORDER')}
              type="number"
              setData={(event: string) => handlePageOrderChanged(Number(event))}
            />
          </Grid>

          <Grid item xs={6} md={6} xl={3} lg={6}>
            <AutocompleteComponent
              multiple
              value={selectedPage.assets}
              label={t('ASSETS')}
              setData={handleAssetsChanged}
              options={assetOpts as string[]}
            />
          </Grid>

          <Grid item xs={6} md={6} xl={3} lg={6}>
            <p>
              {t('DEFAULT_PAGE')}
              <Switch
                className={`active-switch ${selectedPage.isDefault ? 'active' : 'deactive'}`}
                checked={selectedPage.isDefault}
                onChange={handleDefaultPageChanged}
              />
            </p>
          </Grid>
        </Grid>
        <Grid
          xs={7}
          sm={6}
          md={6}
          lg={5}
          xl={4}
          container
          direction="row"
          justifyContent="flex-end">
          <Button
            type="button"
            disabled={isNewPage}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsConfirmOpen(true);
            }}
            variant="contained"
            color="secondary"
            disableElevation
            className="text-transform-none border-radius-0">
            {t('DELETE_PAGE')}
          </Button>
          <Button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleReset();
            }}
            variant="contained"
            color="secondary"
            disableElevation
            className="text-transform-none border-radius-0">
            {t('RESET_ALL_PAGES')}
          </Button>
          <Button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit();
            }}
            variant="contained"
            color="secondary"
            disableElevation
            className="text-transform-none border-radius-0">
            {t('SUBMIT')}
          </Button>
        </Grid>
      </Grid>

      <DefaultModal
        open={isConfirmOpen}
        className="confirmation-modal"
        title={t('DELETE_PAGE')}
        body={<p> {t('DELETE_PAGE_BODY')} </p>}
        setConfirm={() => {
          handleDelete();
          setIsConfirmOpen(false);
        }}
        onClose={() => {
          setIsConfirmOpen(false);
        }}
        confirmText={t('Confirm')}
        denyText={t('Cancel')}
      />
    </>
  );
};

export default AboutPageConfigs;
