import React from 'react';
import IContextLocals from '../../../../utils/entities/screen/IContextLocals';
import IScreen from '../../../../utils/entities/screen/IScreen';
import ISet from '../../../../utils/entities/screen/ISet';
import {IAccessToken} from '../../../../utils/entities/authentication';
import ScrollView from './ScrollView';
import DefaultView from './DefaultView';
import IContextLocalsEntity from '../../../../utils/entities/screen/IContextLocalsEntity';

const TaskViewContainer = (props: {
  contextLocals: IContextLocals;
  requestBody: IScreen;
  assignee: string | undefined;
  loggedUser: string | undefined;
  selectedEntity: string;
  disabled: boolean;
  updatedLocals: (newContextLocals: IContextLocals) => void;
  updateValue: (attribute: string, value: Array<string>) => void;
  updateHeader: () => void;
  activeTabId: string;
  setIndex: number;
  count: (set: ISet, setIndex: number) => number;
  userInfo: IAccessToken;
  locals: IContextLocalsEntity;
  taskView: boolean;
  headerHeight: number;
}) => {
  const {
    contextLocals,
    requestBody,
    assignee,
    loggedUser,
    selectedEntity,
    disabled,
    updatedLocals,
    updateValue,
    updateHeader,
    activeTabId,
    setIndex,
    count,
    userInfo,
    taskView,
    locals,
    headerHeight,
  } = props;
  return (
    <div
      className="taskView questionnaire"
      id={activeTabId}
      style={{ height: `calc(100vh - ${headerHeight}px)` }}>
      {taskView && (
        <ScrollView
          contextLocals={contextLocals}
          requestBody={requestBody}
          assignee={assignee}
          loggedUser={loggedUser}
          disabled={disabled}
          locals={locals}
          updatedLocals={updatedLocals}
          updateValue={updateValue}
          updateHeader={updateHeader}
          activeTabId={activeTabId}
          setIndex={setIndex}
          count={count}
          userInfo={userInfo}
          selectedEntity={selectedEntity}
        />
      )}
      {!taskView && (
        <DefaultView
          contextLocals={contextLocals}
          requestBody={requestBody}
          assignee={assignee}
          loggedUser={loggedUser}
          disabled={disabled}
          locals={locals}
          updatedLocals={updatedLocals}
          updateValue={updateValue}
          updateHeader={updateHeader}
          activeTabId={activeTabId}
          setIndex={setIndex}
          selectedEntity={selectedEntity}
        />
      )}
    </div>
  );
};

export default TaskViewContainer;
