import React, { useEffect, useState } from 'react';
import { Box, Tab } from '@mui/material';
import AboutPage from '../about-page/AboutPage';
import TabsComponent from '../../components/generic/tabs/TabsComponent';
import ITab from '../../utils/entities/screen/ITab';
import { t } from 'i18next';
import { CONSTANTS } from '../../utils/constants/constants';
import GeneralUtils from '../../utils/functions/generalUtils';
import { PermissionTypes } from '../../utils/entities/role/IPermission';
import { useNavigate } from 'react-router-dom';
import { addAlert } from '../../store/actions/alerts.actions';
import './ConfigPage.scss';

const MOCK_TABS: Array<ITab> = [
  {
    tabId: '0',
    title: 'ABOUT_PAGE',
    comp: <AboutPage editMode />,
    type: 'COMP',
    visible: true,
    visibilityExpression: null,
    permissionType: PermissionTypes.ABOUT_PAGE_CONFIG,
  },
];

interface ITabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} className="tab-panel" {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const ConfigPage = () => {
  const navigate = useNavigate();

  const [tabs, setTabs] = useState<Array<ITab>>(
    MOCK_TABS.filter((tab) =>
      GeneralUtils.checkUserPermissions(tab.permissionType as PermissionTypes)
    )
  );
  const [activeTabId, setActiveTabId] = useState<string>('');
  const [userCanAccess, setUserCanAccess] = useState<boolean>(
    GeneralUtils.checkUserPermissions(PermissionTypes.CONFIG_PAGE)
  );

  useEffect(() => {
    tabs.length > 0 ? setActiveTabId(tabs[0].tabId) : setActiveTabId('');
  }, [tabs]);

  useEffect(() => {
    !userCanAccess ? navigate(CONSTANTS.PAGES_URL.INBOX) : null;
    setTimeout(() => {
      setUserCanAccess(GeneralUtils.checkUserPermissions(PermissionTypes.CONFIG_PAGE));
    }, 5000);

    if (!userCanAccess) {
      addAlert({
        type: 'error',
        primaryText: t('ACCESS_NOT_GRANTED'),
      });
    }
  }, [userCanAccess]);

  return (
    <Box className="config-page">
      {activeTabId && (
        <TabsComponent
          activeTabId={activeTabId}
          tabs={tabs}
          tabChanged={setActiveTabId}
          tabsMap={tabs.map((tab) => (
            <Tab value={tab.tabId} label={t(tab.title)} key={tab.tabId} />
          ))}
        />
      )}
      {tabs.map((tab: ITab) => (
        <TabPanel value={tab.tabId} index={tab.tabId} key={tab.tabId}>
          {tab.comp}
        </TabPanel>
      ))}
    </Box>
  );
};

export default ConfigPage;
