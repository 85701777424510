import React from 'react';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getPDFById } from '../../../service/due-diligence.service';
import DownloadIcon from '@mui/icons-material/Download';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import packageJson from '../../../../package.json';
import {IRequestStatus} from '../../../utils/entities/dueDiligence';

const PDFViewer = (props: {
  requestId: string | number | undefined;
  requestStatus: IRequestStatus | undefined;
}) => {
  const { t } = useTranslation();
  const { requestId, requestStatus } = props;
  const pdfjsVersion = packageJson.dependencies['pdfjs-dist']; /*.replace(/\^/g, '')*/

  return (
    <>
      {requestId && requestStatus?.status?.toLowerCase() === 'Complete'.toLowerCase() ? (
        <>
          <div className="downloadPDFButton">
            <Tooltip title={t('DOWNLOAD_PDF')}>
              <Button variant="contained" href={getPDFById(requestId)}>
                <DownloadIcon />
              </Button>
            </Tooltip>
          </div>
          <div className="card-view search-criteria width-100-per show-scroll overflow-y-scroll pdf-viewer">
            <div className="pdf">
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
                <Viewer fileUrl={getPDFById(requestId)} />
              </Worker>
            </div>
          </div>
        </>
      ) : (
        <div className="card-view search-criteria width-100-per show-scroll overflow-y-scroll pdf-viewer">
          <p>{t('PDF_MSG')}</p>
        </div>
      )}
    </>
  );
};
export default PDFViewer;
