import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import IRefData from '../../../../utils/entities/screen/IRefData';
import { IMetadataInputs } from '../../../../utils/entities/meta-data/IMetaDataInput';

const SelectMetadata = (props: IMetadataInputs) => {
  const { attribute, showLabel, valueUpdated } = { ...props };

  const onFilterSelectedChanged = (val: Array<string> | string | null) => {
    if (attribute.multiple) {
      attribute.values = val as Array<string>;
    } else {
      attribute.value = val as string;
    }
    valueUpdated();
  };

  const getValue = () => {
    return attribute.multiple ? attribute.values : attribute.value ?? '';
  };

  return (
    <Select
      variant="standard"
      label={showLabel ? attribute.title : ''}
      name={attribute.name}
      value={getValue()}
      onChange={(e: SelectChangeEvent<string | string[] | null>) =>
        onFilterSelectedChanged(e.target.value)
      }
      multiple={attribute.multiple}
      fullWidth>
      {attribute.enum?.map((option: IRefData) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectMetadata;
