import { FormControl, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextFieldComponent from '../../../../generic/inputs/text-field/TextFieldComponent';
import { useTranslation } from 'react-i18next';
import { ICaseLinkProps } from '../../../../../utils/entities/case/CaseHeader/case-link/ICaseLink';
import { ICaseLinkTag } from '../../../../../utils/entities/case/CaseHeader/ICaseLinkTag';
import GeneralUtils from '../../../../../utils/functions/generalUtils';
import { PermissionTypes } from '../../../../../utils/entities/role/IPermission';
import CaseLinkTable from './case-link-table/CaseLinkTable';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { getSearchRelatedCaseFiles } from '../../../../../service/case-manager.service';
import { ISearchCase } from '../../../../../utils/entities/case/ISearchCase';
import { getCaseLinkOptions } from '../../../../../service/config-manager.service';
import IRefData from '../../../../../utils/entities/screen/IRefData';

const CaseLink = ({
  valueInfo,
  setValueInfo,
  internalStatus,
  typedCase,
  setTypedCase,
  cases,
  setCases,
  setLinkCaseSelected,
}: ICaseLinkProps) => {
  const { t } = useTranslation();
  const canLinkCases = GeneralUtils.checkUserPermissions(PermissionTypes.LINK_CASES_CREATE);

  const [isRowsLoading, setIsRowsLoading] = useState<boolean>(false);
  const [caseLinkInfo, setCaseLinkInfo] = useState<Array<ICaseLinkTag>>([]);

  useEffect(() => {
    getCaseLinkOptions().then((res: Array<ICaseLinkTag>) => {
      setCaseLinkInfo(res);
    });
  }, []);

  useEffect(() => {
    if (typedCase.length >= 1) {
      retrieveCases(typedCase);
    }
  }, [typedCase]);

  const retrieveCases = (id: string) => {
    setIsRowsLoading(true);
    getSearchRelatedCaseFiles(id)
      .then((res: ISearchCase) => {
        setCases(res);
      })
      .catch(() => {
        setCases(null);
      })
      .finally(() => {
        setIsRowsLoading(false);
      });
  };

  // TODO: Check this logic
  const rows = cases
    ? cases.cases.map((caseDetails) => {
        const row: any = {};
        row.id = caseDetails.caseId;
        row.caseId = caseDetails.caseId;
        caseDetails.attributes.forEach((at: IRefData) => {
          row[at.label] = at.value;
        });
        return row;
      })
    : [];

  const dynamicColumns: GridColDef[] = cases
    ? cases.columns.map((caseDetails) => {
        return {
          field: caseDetails,
          headerName: caseDetails,
          width: 130,
        };
      })
    : [];

  const columns: GridColDef[] = [
    {
      field: 'caseId',
      headerName: 'Case ID',
      width: 300,
    },
    ...dynamicColumns,
  ];

  const handleRowSelection = (e: GridRowSelectionModel) => {
    setLinkCaseSelected(e[0] as number);
  };

  return (
    <>
      {canLinkCases && (
        <>
          <p>{t('CASE_LINK.DESCRIPTION')}</p>
          <Stack direction="row" spacing={2}>
            <Stack sx={{ width: '100%' }}>
              {t('CASE_LINK.CASE_NUMBER')}
              <TextFieldComponent
                type="text"
                value={typedCase}
                setData={(value: string) => {
                  setTypedCase(value);
                }}
                disabled={internalStatus}
              />
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <div style={{ marginBlockEnd: '1em', marginBottom: '1%' }}>
                {t('CASE_LINK.RELATIONSHIP')}
              </div>
              <FormControl variant="standard" size="small">
                <Select
                  displayEmpty
                  variant="outlined"
                  data-testid="select"
                  value={valueInfo}
                  onChange={(event: SelectChangeEvent) => setValueInfo(event.target.value)}
                  disabled={internalStatus}>
                  <MenuItem disabled value="">
                    <em>{t('SELECT_OPTION')}</em>
                  </MenuItem>
                  {caseLinkInfo.map((relationship: ICaseLinkTag) => (
                    <MenuItem
                      data-testid="select-opt1"
                      key={`${relationship.targetCaseLinkType}-${relationship.sourceCaseLinkType}`}
                      value={relationship.targetCaseLinkType}>
                      {relationship.targetCaseLinkType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>

          <CaseLinkTable
            typedCase={typedCase}
            cases={cases}
            rows={rows}
            columns={columns}
            handleRowSelection={handleRowSelection}
            isRowsLoading={isRowsLoading}
          />
        </>
      )}
    </>
  );
};

export default CaseLink;
